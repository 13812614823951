// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chat-bot-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\chat-bot.tsx" /* webpackChunkName: "component---src-pages-chat-bot-tsx" */),
  "component---src-pages-details-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-faqs-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-results-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-pages-search-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("D:\\root\\agent4\\_work\\24\\s\\src\\pages\\sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */)
}

